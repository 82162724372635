import { Box, Button, colors, Container, Divider, Modal, Slider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { set } from "lodash";
import { useState } from "react";

interface FeedbackModalProps {
    open: boolean;
    onClose: () => void;
}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: colors.grey[300],
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: '25px'
};

const commonSliderStyle = {
    ".MuiSlider-mark": {
        visibility: "hidden"
    },
    ".MuiSlider-track": {
        visibility: "hidden"
    },
    ".MuiSlider-rail": {
        opacity: 1,
        outline: `10px solid ${colors.grey[400]}`,
        backgroundColor: colors.grey[400],
    },
    ".MuiSlider-markLabel": {
        color: colors.grey[900]
    },
    width: "200px",
};

function valuetext(value: number) {
    return `${value}°C`;
}

export default function FeedbackModal(props: FeedbackModalProps) {
    const [air, setAir] = useState<number>(0);
    const [temperature, setTemperature] = useState<number>(1);
    const [humidity, setHumidity] = useState<number>(1);
    const [noise, setNoise] = useState<number>(0);
    const [light, setLight] = useState<number>(1);


    return (<Modal
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <Box sx={modalStyle}>
            <Typography align="center">User feedback</Typography>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[800],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Air Quality</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too dusty',
                        }]}
                    min={0}
                    max={1}
                    value={air}
                    onChange={(_, value) => setAir(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (air == 0 ? "green" : "red"),
                        },
                        width: "100px"
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Temperature</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'too Cold',
                        },
                        {
                            value: 1,
                            label: 'Ok',
                        },
                        {
                            value: 2,
                            label: 'too hot',
                        }]}
                    min={0}
                    max={2}
                    value={temperature}
                    onChange={(_, value) => setTemperature(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (temperature == 1 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            {/*<Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />

            <Container sx={{ paddingLeft: "15px" }}>
                <Typography>Temperature</Typography>
                <ToggleButtonGroup
                    color="primary"
                    value={temp2}
                    exclusive
                    onChange={(_, value) => setTemp2(value)}
                    sx={{
                        ".MuiToggleButton-root.Mui-selected.ToggleRed": {
                            color: "red"
                        },
                        ".MuiToggleButton-root.Mui-selected.ToggleGreen": {
                            color: "green"
                        }
                    }
                    }
                >
                    <ToggleButton value="0" className="ToggleRed">too Cold</ToggleButton>
                    <ToggleButton value="1" className="ToggleGreen">Ok</ToggleButton>
                    <ToggleButton value="2" className="ToggleRed">too Hot</ToggleButton>
                </ToggleButtonGroup>
            </Container> */}
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Humidity</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'too dry',
                        },
                        {
                            value: 1,
                            label: 'Ok',
                        },
                        {
                            value: 2,
                            label: 'too humid',
                        }]}
                    min={0}
                    max={2}
                    value={humidity}
                    onChange={(_, value) => setHumidity(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (humidity == 1 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Acoustic Comfort</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'Ok',
                        },
                        {
                            value: 1,
                            label: 'too noisy',
                        }]}
                    min={0}
                    max={1}
                    value={noise}
                    onChange={(_, value) => setNoise(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (noise == 0 ? "green" : "red"),
                        },
                        width: "100px"
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />
            <Container sx={{ paddingLeft: "15px", display: "flex" }} className="justify-between">
                <Typography>Visual Comfort</Typography>
                <Slider
                    aria-label="Restricted values"
                    defaultValue={0}
                    step={null}
                    valueLabelDisplay="off"
                    marks={[
                        {
                            value: 0,
                            label: 'too dark',
                        },
                        {
                            value: 1,
                            label: 'Ok',
                        },
                        {
                            value: 2,
                            label: 'too light',
                        }]}
                    min={0}
                    max={2}
                    value={light}
                    onChange={(_, value) => setLight(value as number)}
                    sx={{
                        ...commonSliderStyle,
                        ".MuiSlider-thumb": {
                            backgroundColor: (light == 1 ? "green" : "red")
                        }
                    }}
                />
            </Container>
            <Divider
                variant="middle"
                style={{
                    borderBottomColor: colors.grey[500],
                }}
            />

            <Button onClick={props.onClose}
                sx={{
                    display: "block",
                    margin: "auto",
                    borderRadius: "20px",
                    textTransform: "capitalize"
                }}
                variant="contained">
                Submit
            </Button>

        </Box>
    </Modal>)
}