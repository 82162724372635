import Keycloak, { KeycloakConfig } from "keycloak-js";

const initOptions: KeycloakConfig = {
    url: "https://keycloak.toolbox.epu.ntua.gr",
    realm: "INHERIT",
    clientId: "IEQ",
};

const keycloak = new Keycloak(initOptions);

export default keycloak;