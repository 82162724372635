
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import { valueOrDefaultString } from "../Common/Helpers";
import PercentageScaleLine from "./ScaleLines/PercentageScaleLine";
import { Units, UnitToPretty } from "../Common/Enums";

export interface MeanStats {
    air_quality: MeanWithAbnormalities,
    temperature: MeanWithAbnormalities,
    humidity: MeanWithAbnormalities,
    acoustic_comfort: MeanWithAbnormalities,
    visual_comfort: MeanWithAbnormalities,
}


export interface MeanWithAbnormalities {
    mean?: number;
    percentageInBounds?: number,
    unit?: Units,
    label?: string,
}

function PercentageToColorAndEvaluation(percentage?: number): { color: string, evaluation: string } {
    return percentage == null
        ? { color: "Gray", evaluation: "Unknown" }
        : percentage < 20
            ? { color: "red", evaluation: "Very Poor" }
            : percentage < 40
                ? { color: "orange", evaluation: "Poor" }
                : percentage < 60
                    ? { color: "yellow", evaluation: "Medium" }
                    : percentage < 80
                        ? { color: "yellowgreen", evaluation: "Good" }
                        : { color: "green", evaluation: "Excellent" };

}

export default function InsightsGauges({ props }: { props: MeanStats }) {
    return (
        <div className="grid grid-cols-5 grid-rows-4 justify-items-center items-center" style={{ gridTemplateRows: "repeat(4, auto)" }}>
            <div className="col-start-1 col-end-2 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={props.air_quality.percentageInBounds != null ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(props.air_quality.mean, '-')}\n${UnitToPretty(props.air_quality?.unit ?? Units.PPM)}`}
                    sx={(theme) => ({
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: PercentageToColorAndEvaluation(props.air_quality.percentageInBounds).color,
                        },
                    })}
                />
            </div>

            <div className="text-sm col-start-1 col-end-2 row-start-2 row-end-3">Air Quality</div>
            <div className="text-xl font-bold col-start-1 col-end-2 row-start-3 row-end-4">{props.air_quality?.label ?? PercentageToColorAndEvaluation(props.air_quality.percentageInBounds).evaluation}</div>
            <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                <PercentageScaleLine value={props.air_quality.percentageInBounds} />
            </div>
            <div className="grid grid-cols-subgrid grid-rows-subgrid col-start-2 col-end-4 row-start-1 row-end-5 justify-items-center items-center"> {/*bg-yellow-100*/}

                {/* <div className="grid grid-cols-subgrid grid-rows-subgrid col-start-1 col-end-3 row-start-2 row-end-4 items-center">
                    <div className="text-sm w-full col-start-1 col-end-3 row-start-1 row-end-2">Thermal <br /> Comfort</div>

                    <div className="text-xl font-bold col-start-1 col-end-3 row-start-2 row-end-3">{PercentageToColorAndEvaluation(props.temperature.abnormalPercentage).evaluation}</div>
                </div> */}

                <div className="col-start-1 col-end-2 row-start-1 row-end-2">
                    <div style={{ margin: "0 auto" }}>
                        <Gauge
                            width={150}
                            height={150}
                            value={props.temperature.percentageInBounds != null ? 1 : 0}
                            valueMax={1}
                            text={({ value }) => `${valueOrDefaultString(props.temperature.mean, '-')}\n${UnitToPretty(props.temperature?.unit ?? Units.DegreesCelsius)}`}
                            sx={{
                                [`& .${gaugeClasses.valueText}`]: {
                                    fontWeight: "bold",
                                },
                                [`& .${gaugeClasses.valueArc}`]: {
                                    fill: PercentageToColorAndEvaluation(props.temperature.percentageInBounds).color,
                                },
                            }}
                        />
                    </div>
                </div>

                <div className="text-sm col-start-1 col-end-2 row-start-2 row-end-3">Temperature</div>
                <div className="text-xl font-bold col-start-1 col-end-2 row-start-3 row-end-4">{props.temperature?.label ?? PercentageToColorAndEvaluation(props.temperature.percentageInBounds).evaluation}</div>
                <div className="col-start-1 col-end-2 row-start-4 row-end-5 pb-4">
                    <PercentageScaleLine value={props.temperature.percentageInBounds} />
                </div>

                <div className="col-start-2 col-end-3 row-start-1 row-end-2">
                    <Gauge
                        width={150}
                        height={150}
                        value={props.humidity.percentageInBounds != null ? 1 : 0}
                        valueMax={1}
                        text={({ value }) => `${valueOrDefaultString(props.humidity.mean, '-')}\n${UnitToPretty(props.humidity?.unit ?? Units.Percent)}`}
                        sx={{
                            [`& .${gaugeClasses.valueText}`]: {
                                fontWeight: "bold",
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: PercentageToColorAndEvaluation(props.humidity.percentageInBounds).color,
                            },
                        }}
                    />

                </div>

                <div className="text-sm col-start-2 col-end-3 row-start-2 row-end-3">Humidity</div>
                <div className="text-xl font-bold col-start-2 col-end-3 row-start-3 row-end-4">{props.humidity?.label ?? PercentageToColorAndEvaluation(props.humidity.percentageInBounds).evaluation}</div>
                <div className="col-start-2 col-end-3 row-start-4 row-end-5 pb-4">
                    <PercentageScaleLine value={props.humidity.percentageInBounds} />
                </div>
            </div>
            <div className="col-start-4 col-end-5 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={props.acoustic_comfort.percentageInBounds != null ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(props.acoustic_comfort.mean, '-')}\n${UnitToPretty(props.acoustic_comfort.unit ?? Units.Decibel)}`}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: PercentageToColorAndEvaluation(props.acoustic_comfort.percentageInBounds).color,
                        },
                    }}
                />
            </div>
            <div className="text-sm col-start-4 col-end-5 row-start-2 row-end-3">Acoustic Comfort</div>
            <div className="text-xl font-bold col-start-4 col-end-5 row-start-3 row-end-4">{props.acoustic_comfort?.label ?? PercentageToColorAndEvaluation(props.acoustic_comfort.percentageInBounds).evaluation}</div>
            <div className="col-start-4 col-end-5 row-start-4 row-end-5 pb-4">
                <PercentageScaleLine value={props.acoustic_comfort.percentageInBounds} />
            </div>
            <div className="col-start-5 col-end-6 row-start-1 row-end-2">
                <Gauge
                    width={150}
                    height={150}
                    value={props.visual_comfort.percentageInBounds != null ? 1 : 0}
                    valueMax={1}
                    text={({ value }) => `${valueOrDefaultString(props.visual_comfort.mean, '-')}\n${UnitToPretty(props.visual_comfort?.unit ?? Units.Lux)}`}
                    sx={{
                        [`& .${gaugeClasses.valueText}`]: {
                            fontWeight: "bold",
                        },
                        [`& .${gaugeClasses.valueArc}`]: {
                            fill: PercentageToColorAndEvaluation(props.visual_comfort.percentageInBounds).color,
                        },
                    }}
                />
            </div>
            <div className="text-sm col-start-5 col-end-6 row-start-2 row-end-3">Visual Comfort</div>
            <div className="text-xl font-bold col-start-5 col-end-6 row-start-3 row-end-4">{props.visual_comfort?.label ?? PercentageToColorAndEvaluation(props.visual_comfort.percentageInBounds).evaluation}</div>
            <div className="col-start-5 col-end-6 row-start-4 row-end-5 pb-4">
                <PercentageScaleLine value={props.visual_comfort.percentageInBounds} />
            </div>
        </div >
    );
}